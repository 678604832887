@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
.c-topicspath {
  width: 100%;
  margin: 0 auto; }
  @media screen and (max-width: 768px) {
    .c-topicspath {
      text-align: left; } }
  .c-topicspath li {
    display: inline-block;
    font-size: 12px;
    color: #888888; }
    @media screen and (max-width: 768px) {
      .c-topicspath li {
        font-size: 0.7em; } }
    .c-topicspath li:after {
      content: "\f105";
      font-family: "Font Awesome 5 Free";
      font-weight: 600;
      display: inline-block;
      margin-left: 8px; }
    .c-topicspath li:last-child:after {
      content: none; }
    .c-topicspath li a {
      color: inherit;
      position: relative; }
      .c-topicspath li a:before {
        content: '';
        width: 100%;
        height: 1px;
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #888888; }
      .c-topicspath li a[href^="javascript:void(0)"]:before {
        content: none; }

.l-lowerCaption {
  position: relative;
  width: 100%;
  height: 282px;
  background-color: #F7F7F7;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow-x: hidden; }
  @media screen and (max-width: 1200px) {
    .l-lowerCaption {
      padding: 0 40px; } }
  .l-lowerCaption:before, .l-lowerCaption:after {
    content: '';
    width: 0;
    height: 0;
    display: block;
    border-style: solid;
    position: absolute; }
  .l-lowerCaption:before {
    border-width: 258px 42px 0 0;
    border-color: #0F2884 transparent transparent transparent;
    top: 0;
    left: 0; }
  .l-lowerCaption:after {
    border-width: 0 0 258px 42px;
    border-color: transparent transparent #0F2884 transparent;
    bottom: 0;
    right: 0; }
  .l-lowerCaption__inner {
    position: relative;
    max-width: 1134px;
    margin: 0 auto;
    height: 100%; }
    .l-lowerCaption__inner-block {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%); }
  .l-lowerCaption__title {
    color: #333;
    font-size: 38px;
    width: 100%;
    margin: 0 0 -5px; }
    @media screen and (max-width: 768px) {
      .l-lowerCaption__title {
        font-size: 28px;
        width: 100%; } }
    .l-lowerCaption__title small {
      font-size: 18px;
      color: #0F2884;
      text-transform: uppercase;
      font-style: italic;
      display: block;
      margin-bottom: -8px; }
      .l-lowerCaption__title small:empty {
        display: none; }
